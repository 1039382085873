<template>
  <SimpleTableForm
    :id="id"
    title="Create PlantingType"
    :table-config="tableConfig"
    :return-route="adminRoute"
    :server-type="serverType"
    @created="$emit('created')"
  />
</template>

<script>

import { plantingTypeForm } from './constants'
import SimpleTableForm from '@/components/form/SimpleTableForm'

export default {
  name: 'PlantingTypeForm',
  namespaced: true,
  components: { SimpleTableForm },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      tableConfig: plantingTypeForm,
      serverType: 'planting_types',
      adminRoute: '/admin/plantingTypes'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
