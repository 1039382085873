export const plantingTypeFields = [

  {
    name: 'lowWaterFactor',
    label: 'Low Water Factor',
    dataType: 'number',
    suffix: 'kgCO₂e/kg',
    prefix: 'pt'
  },
  {
    name: 'mediumWaterFactor',
    label: 'Median Water Factor',
    dataType: 'number',
    suffix: 'kgCO₂e/kg',
    prefix: 'pt'
  },
  {
    name: 'highWaterFactor',
    label: 'High Water Factor',
    dataType: 'number',
    suffix: 'kgCO₂e/kg',
    prefix: 'pt'
  }
]

export const plantingTypeForm = [
  {
    name: 'name',
    label: 'Name',
    rules: 'required'
  },
  {
    name: 'cpdPlantingTypeId',
    label: 'CPD Planting Type Id'
  },
  ...plantingTypeFields,
  {
    name: 'notes',
    label: 'Notes'
  },
  {
    name: 'citations',
    label: 'Citations'
  }
]

export const elementTypeHasField = (element) => {
  return element.cpdVersion === 3 && element.type === 'hydrozone'
}
